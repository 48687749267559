import { Typeahead } from '@zerintia/powerstone-ui';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const TagInput = ({ tags: defaultTags = [], name }) => {
  const { t } = useTranslation();

  return (
    <Controller
      render={({ onChange: onFormChange }) => (
        <Typeahead
          name={name}
          inputProps={{ name: name }}
          id={name}
          allowNew
          defaultSelected={defaultTags}
          multiple
          newSelectionPrefix={t('ADD_TAG')}
          options={[]}
          placeholder={t('ADD_TAG')}
          onChange={(e) => {
            onFormChange(
              e.map((tag) => (typeof tag === 'string' ? tag : tag.label))
            );
          }}
          emptyLabel={''}
        />
      )}
      defaultValue={defaultTags}
      name={name}
    />
  );
};

export default TagInput;

import {
  FontAwesomeIcon,
  faChevronDown,
  faChevronRight,
} from '@zerintia/powerstone-icons';
import { ServerSideTable } from '@zerintia/powerstone-table';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDrag, useDragLayer, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import styled, { css } from 'styled-components';

const cellBefore = (props) => css`
  &:before {
    background-color: LightBlue;
    border: dashed 2px black;
    border-radius: 20px;
    box-sizing: border-box;
    content: '';
    display: block;
    height: calc(100% + 20px);
    opacity: 0.2;
    position: absolute;
    right: -20px;
    top: -10px;
    width: calc(100% - (${props.paddingLeft}) + 40px);
  }
`;

const Cell = styled.span`
  padding-left: calc(${(props) => props.paddingLeft});
  position: relative;

  ${(props) => props.isDragging && cellBefore(props)}
`;

const dropBefore = (props) => css`
  &:before {
    background-color: ${props.canDrop ? 'Aquamarine' : 'LightCoral'};
    border: dashed 2px black;
    border-radius: 20px;
    box-sizing: border-box;
    content: '';
    display: block;
    height: calc(100% + 20px);
    opacity: 0.2;
    position: absolute;
    right: -10px;
    top: -10px;
    width: calc(100% - (${props.paddingLeft}) + 20px);
  }
`;

const DropItem = styled.div`
  height: 100%;
  position: relative;
  width: fit-content;

  ${(props) => props.isOver && dropBefore(props)}
`;

const Indicator = styled.div`
  background-color: black;
  height: calc(50% + 0.75rem - 2px);
  left: calc(${(props) => props.position * 20}px + 0.4rem);
  position: absolute;
  width: 1px;
`;

const IndicatorHorizontal = styled.div`
  background-color: black;
  height: 0.5px;
  left: ${(props) => props.position * 20 - 13}px;
  position: absolute;
  top: 50%;
  width: calc(10px + ${(props) => (props.chevron ? 0 : 0.7)}rem);
`;

const short = css`
  height: calc(50% + 0.2rem);
`;

const IndicatorInf = styled(Indicator)`
  bottom: -0.75rem;
  height: calc(50% + 0.75rem + 3px);

  ${(props) => props.short && short}
`;

const IndicatorSup = styled(Indicator)`
  top: calc(-0.75rem - 1px);
`;

const CellDragLayer = memo(() => {
  const { currentOffset, isDragging, item } = useDragLayer((monitor) => ({
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
  }));

  if (!isDragging) {
    return null;
  }

  const getItemStyles = (localCurrentOffset) => {
    if (!localCurrentOffset) {
      return {
        display: 'none',
      };
    }
    let { x, y } = localCurrentOffset;
    const transform = `translate(${x}px, ${y}px)`;
    return {
      WebkitTransform: transform,
      left: 0,
      pointerEvents: 'none',
      position: 'fixed',
      top: 0,
      transform,
      zIndex: 100,
    };
  };

  const { children, depth, value } = item;

  return (
    <Cell
      isDragging={isDragging}
      paddingLeft={`${depth * 20}px + ${
        (children || []).length > 0 ? 0 : 0.9
      }rem`}
      style={getItemStyles(currentOffset)}
    >
      {value}
    </Cell>
  );
});

const renderLastChildLinks = (values, index) => {
  return (
    <>
      <IndicatorSup key={`sup-${values._id}-${index - 1}`} position={index - 1} />
      {(values.open && values.children?.length) ? <IndicatorInf
        key={`inf-${values._id}-${index}`}
        position={index}
        short
      />: <></>}
    </>
  );
};

const renderIntermediateChildLinks = (values, index) => {
  return (
    <>
      <IndicatorInf key={`inf-${values._id}-${index - 1}`} position={index - 1} />
      <IndicatorSup key={`sup-${values._id}-${index - 1}`} position={index - 1} />
      {(values.open && values.children?.length) ? <IndicatorInf
        key={`inf-${values._id}-${index}`}
        position={index}
        short
      />: <></>}
    </>
  );
};

const renderIntermediateLink = (values, data, index) => {
  const thisItemIndex = data.findIndex((item) => item._id === values._id);
  const lastItemAtDepth = data.findLast((item, i) => i <= thisItemIndex && item.depth === index);
  if (lastItemAtDepth.lastChild) {
    return <></>;
  } else {
    return (
      <>
        <IndicatorInf key={`inf-${values._id}-${index - 1}`} position={index - 1} />
        <IndicatorSup key={`sup-${values._id}-${index - 1}`} position={index - 1} />
      </>
    );
  }
};

const getIndicator = (values, data) =>
  Array(values.depth + 1)
    .fill('')
    .map((_, index) => {
      if (
        values.depth >= 0 &&
        index === values.depth
      ) {
        if (values.lastChild) {
          return renderLastChildLinks(values, index);
        } else {
          return renderIntermediateChildLinks(values, index);
        }
      } else {
        if (values.depth > 0 && index < values.depth) {
          return renderIntermediateLink(values, data, index);
        }
      }
      return <></>;
    });

const ZTreeItem = memo(
  ({ checkDrop, data, onDrop, toggleChildren, values, value }) => {
    const [{ isDragging }, drag, preview] = useDrag(() => ({
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
      item: {
        ...values,
        parent: values.parent?._id || values.area?._id,
        value,
      },
      type: 'unit',
    }));
    const [{ canDrop, isOver }, drop] = useDrop(
      () => ({
        accept: 'unit',
        canDrop: (dragItem) => checkDrop(dragItem, values),
        collect: (monitor) => ({
          canDrop: monitor.canDrop(),
          isOver: !!monitor.isOver(),
        }),
        drop: (dragItem) => onDrop(dragItem, values),
      }),
      [],
    );

    useEffect(() => {
      preview(getEmptyImage(), { captureDraggingState: true });
    }, [preview]);

    return (
      <DropItem
        canDrop={canDrop}
        id={value}
        isOver={isOver}
        paddingLeft={`${values.depth * 20}px + ${
          values.children?.length ? 0 : 0.9
        }rem`}
        ref={drop}
      >
        {getIndicator(values, data)}
        <Cell
          onClick={() => toggleChildren(values._id, !values.open)}
          paddingLeft={`${values.depth * 20}px + ${
            values.children?.length ? 0 : 0.9
          }rem`}
          ref={values.type === 'unit' ? drag : null}
          style={
            isDragging
              ? {
                cursor: 'move',
              }
              : {
                cursor: values.children?.length ? 'pointer' : 'default',
              }
          }
        >
          <IndicatorHorizontal
            chevron={values.children?.length}
            position={values.depth}
          />
          {values.children?.length > 0 && (
            <FontAwesomeIcon
              className='mr-1'
              icon={values.open ? faChevronDown : faChevronRight}
            />
          )}
          {value?.match('strong') ? (
            <>
              <strong>{value.match(/<strong>(.+)<\/strong>/)[1]}</strong>
              {value.split('</strong>')[1]}
            </>
          ) : (
            value || '-'
          )}
        </Cell>
      </DropItem>
    );
  },
);

const ZTreeTable = ({
  checkDrop,
  columnsDef,
  data: initialData,
  onDrop,
  ...props
}) => {
  const [data, setData] = useState(initialData);

  useEffect(() => setData(initialData), [initialData]);

  const toggleChildren = useCallback(
    (id, open) => {
      const setOpen = (item) => {
        if (item._id === id) {
          return {
            ...item,
            open,
          };
        } else if ((item.children || []).length > 0) {
          return {
            ...item,
            children: item.children.map(setOpen),
          };
        } else {
          return item;
        }
      };
      setData(data.map(setOpen));
    },
    [data],
  );

  const treeColumnsDef = useMemo(
    () => [
      {
        ...columnsDef[0],
        Cell: ({ data: dataCell, row, value }) => (
          <ZTreeItem
            checkDrop={checkDrop}
            data={dataCell}
            onDrop={onDrop}
            toggleChildren={toggleChildren}
            values={dataCell[row.index]}
            value={value}
          />
        ),
        disableFilters: true,
        disableSortBy: true,
      },
      ...columnsDef.slice(1).map((column) => ({
        ...column,
        disableFilters: true,
        disableSortBy: true,
      })),
    ],
    [checkDrop, columnsDef, onDrop, toggleChildren],
  );

  const treeData = useMemo(() => {
    const setRows = (parent) =>
      (parent.children || []).length > 0 && parent.open
        ? [parent, ...parent.children.flatMap(setRows)]
        : [parent];
    return data.reduce((prev, curr) => [...prev, ...setRows(curr)], []);
  }, [data]);

  return (
    <>
      <CellDragLayer />
      <ServerSideTable columnsDef={treeColumnsDef} data={treeData} {...props} />
    </>
  );
};

export default ZTreeTable;

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { fas, library } from '@zerintia/powerstone-icons';
import { ToastProvider } from '@zerintia/powerstone-ui';

import { UserSessionProvider } from '@zerintia/powerstone-auth';
import MainLayout from 'layout';
import environments from './environments';
import './App.css';
import './custom-style.css';

library.add(fas);

function App() {
  return (
    <UserSessionProvider managementUri={environments.MANAGEMENT_BASE_URL}>
      <BrowserRouter>
        <ToastProvider>
          <MainLayout />
        </ToastProvider>
      </BrowserRouter>
    </UserSessionProvider>
  );
}

export default App;

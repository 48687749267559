import { Button, useToasts } from '@zerintia/powerstone-ui';
import PropTypes from 'prop-types';
import {
  useZForm,
  ZForm,
  ZFormContainer,
  ZFormItem,
  ZInput,
} from '@zerintia/powerstone-form';
import { ErrorBanner, PageContainer } from 'components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { sessionStorageService } from '@zerintia/powerstone-auth';
import { usersApi } from 'services/managementApi';
import { Link, useHistory } from 'react-router-dom';

const RecoveryPassword = ({ location }) => {
  const { t } = useTranslation('core');
  const { showToast } = useToasts();
  const history = useHistory();
  const { password_requirements } = sessionStorageService.getLoginTypes() || {
    password_requirements: { min: 6, max: 12, maxseq: 3 },
  };
  const getHashCode = () => {
    return location.pathname.split('recovery-password/')[1];
  };
  const hashCode = getHashCode();

  const checkPasswordFormat = (pwd, options) => {
    if (!pwd) return { ok: false, err: t('REQUIRED') };

    // Counts
    const min = options.min ? options.min : 10;
    const max = options.max ? options.max : 10;
    const dig = options.dig ? options.dig : 1;
    const upp = options.upp ? options.upp : 1;
    const low = options.low ? options.low : 1;
    const spe = options.spe ? options.spe : 1;
    const maxseq = options.maxseq ? options.maxseq : 3;

    const count = { digit: 0, upper: 0, lower: 0, special: 0 };
    let seq = 1;
    let ms = 1;

    // Length
    if (pwd.length < min)
      return { ok: false, err: t('PASSWORD_LENGTH_LESS', { min }) };
    if (pwd.length > max)
      return { ok: false, err: t('PASSWORD_LENGTH_MORE', { max }) };

    // Verify password content
    for (let i = 0; i < pwd.length; i++) {
      // Count char types
      const c = pwd.charCodeAt(i); // Char
      if (c > 47 && c < 58) count.digit++;
      // Digits
      else if (c > 64 && c < 91) count.upper++;
      // Uppercase letter
      else if (c > 96 && c < 123) count.lower++;
      // Lowercase letter
      else count.special++; // Special

      // Count consecutive chars
      if (i > 0) {
        if (c === pwd.charCodeAt(i - 1)) {
          seq++;
          if (seq > ms) ms = seq;
        } else {
          seq = 1;
        }
      }
    }

    let error = { ok: true, err: '' };
    // Check
    if (count.digit < dig)
      error = {
        ok: false,
        err: t('PASSWORD_DIGITS', { dig }),
      };
    if (count.upper < upp)
      error = {
        ok: false,
        err: t('PASSWORD_UPP', { upp }),
      };
    if (count.lower < low)
      error = {
        ok: false,
        err: t('PASSWORD_LOW', { low }),
      };
    if (count.special < spe)
      error = {
        ok: false,
        err: t('PASSWORD_SPE', { spe }),
      };
    if (ms > maxseq)
      error = {
        ok: false,
        err: t('PASSWORD_CONSECUTIVE', { maxseq }),
      };

    // Ok
    return error;
  };

  const changePassword = yup.object().shape({
    password: yup
      .string()
      .required(t('REQUIRED'))
      .test(
        'password',
        (value) => checkPasswordFormat(value.value, password_requirements).err,
        (value) => checkPasswordFormat(value, password_requirements).ok,
      ),
    confirm_password: yup
      .string()
      .required(t('REQUIRED'))
      .oneOf([yup.ref('password'), null], t('PASSWORD_MUST_MATCH')),
  });
  const [form] = useZForm({ validationSchema: changePassword });
  const handleChangePassword = async (data) => {
    try {
      await usersApi.regenerateCredentialsRecoveryPasswordLink({
        hashCode,
        recoveryPasswordInputDTO: {
          newPassword: data.password,
        },
      });
      showToast('success', t('RECOVERY_PASSWORD_SUCCESS'));
      history.replace({
        pathname: '/pages/login',
      });
    } catch (err) {
      showToast('error', t('OPERATION_FAILED'));
    }
  };

  if (!hashCode) {
    return (
      <PageContainer>
        <ErrorBanner />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <ZFormContainer
        cardClassName='box-shadow-none border-none bg-transparent'
        cardBodyClassName='p-0'
        title={t('RECOVERY_PASSWORD')}
      >
        <ZForm form={form} onSubmit={handleChangePassword}>
          <ZFormItem
            label={t('PASSWORD')}
            htmlFor='password'
            labelSize={12}
            inputSize={12}
          >
            <ZInput
              name='password'
              type='password'
              placeholder={t('PASSWORD')}
            />
          </ZFormItem>
          <ZFormItem
            label={t('REPEAT_PASSWORD')}
            htmlFor='confirm_password'
            labelSize={12}
            inputSize={12}
          >
            <ZInput
              name='confirm_password'
              type='password'
              placeholder={t('REPEAT_PASSWORD')}
            />
          </ZFormItem>

          <div className='d-flex justify-content-between align-items-center mt-3'>
            <Button
              type='submit'
              color='primary'
              className='align-self-center'
              block
            >
              {t('CHANGE_PASSWORD')}
            </Button>
          </div>
        </ZForm>
      </ZFormContainer>
      <div className='d-flex mt-3 justify-content-center'>
        <Link
          to={{
            pathname: '/pages/login',
          }}
        >
          {t('GO_BACK_LOGIN')}
        </Link>
      </div>
    </PageContainer>
  );
};

RecoveryPassword.defaultProps = {
  location: { state: {} },
};

RecoveryPassword.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
};
export default RecoveryPassword;

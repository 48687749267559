import * as React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Badge } from '@zerintia/powerstone-ui';

const TableCellList = ({ value, labelKey }) => {
  return value.map((item, index) => (
    <Badge key={`${get(item, labelKey)}-${index}`} color='primary' className='mr-2'>
      {get(item, labelKey)}
    </Badge>
  ));
};

TableCellList.defaultProps = {
  value: [],
};

TableCellList.propTypes = {
  value: PropTypes.array,
};

export default TableCellList;

import {
  AreaClassesApi,
  AreasApi,
  AuthApi,
  UsersApi,
  UserSessionsApi,
  SitesApi,
  RolesApi,
  ResourcesApi,
  NodesApi,
  ProfilesApi,
  ExternalUsersApi,
  CustomersApi,
  LanguagesApi,
  LayersApi,
  LevelsApi,
  ProductsApi,
  ProductionIncidenceClassesApi,
  PropertiesApi,
  PropertyclassesApi,
  StatesApi,
  UnitsApi,
  UnitClassesApi,
  ClientApi,
  VirtualStatesApi,
  ProductClassesApi,
  UpdateVersionsApi,
  UploadFilesApi,
  FilesApi,
  ZonesApi,
  DeviceClassesApi,
  DevicesApi,
  UserStatesApi,
  EnterprisesApi,
  GroupLicensesApi,
  FileAssociationsApi,
  FileAssociationEntityTypeEnum,
} from '@zerintia/4management-client';
import { apiConfig } from '@zerintia/powerstone-auth';
import environments from '../environments';

const instance = apiConfig.init(window.location.origin);

const areaClassesApi = new AreaClassesApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);
const areasApi = new AreasApi({}, environments.MANAGEMENT_BASE_URL, instance);
const authApi = new AuthApi({}, environments.MANAGEMENT_BASE_URL, instance);
const usersApi = new UsersApi({}, environments.MANAGEMENT_BASE_URL, instance);
const sitesApi = new SitesApi({}, environments.MANAGEMENT_BASE_URL, instance);
const rolesApi = new RolesApi({}, environments.MANAGEMENT_BASE_URL, instance);
const resourcesApi = new ResourcesApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);
const nodesApi = new NodesApi({}, environments.MANAGEMENT_BASE_URL, instance);
const fileAssociationsApi = new FileAssociationsApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);
const groupLicensesApi = new GroupLicensesApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);
const profilesApi = new ProfilesApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);
const customersApi = new CustomersApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);
const languagesApi = new LanguagesApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);
const levelsApi = new LevelsApi({}, environments.MANAGEMENT_BASE_URL, instance);
const layersApi = new LayersApi({}, environments.MANAGEMENT_BASE_URL, instance);
const externalusersApi = new ExternalUsersApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);
const usersSessionApi = new UserSessionsApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);
const productClassesApi = new ProductClassesApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);
const productionincidenceclassApi = new ProductionIncidenceClassesApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);
const propertiesApi = new PropertiesApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);
const propertyclassesApi = new PropertyclassesApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);
const productsApi = new ProductsApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);
const updateVersionsApi = new UpdateVersionsApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);
const uploadFilesApi = new UploadFilesApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);
const virtualStatesApi = new VirtualStatesApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);
const statesApi = new StatesApi({}, environments.MANAGEMENT_BASE_URL, instance);
const unitsApi = new UnitsApi({}, environments.MANAGEMENT_BASE_URL, instance);
const unitclassesApi = new UnitClassesApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);

const clientApi = new ClientApi({}, environments.MANAGEMENT_BASE_URL, instance);
const filesApi = new FilesApi({}, environments.MANAGEMENT_BASE_URL, instance);
const zonesApi = new ZonesApi({}, environments.MANAGEMENT_BASE_URL, instance);

const deviceClassApi = new DeviceClassesApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);

const devicesApi = new DevicesApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);

const userStatesApi = new UserStatesApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);

const enterprisesApi = new EnterprisesApi(
  {},
  environments.MANAGEMENT_BASE_URL,
  instance,
);

export {
  FileAssociationEntityTypeEnum,
  areaClassesApi,
  areasApi,
  authApi,
  usersApi,
  usersSessionApi,
  sitesApi,
  rolesApi,
  resourcesApi,
  nodesApi,
  profilesApi,
  externalusersApi,
  customersApi,
  languagesApi,
  layersApi,
  levelsApi,
  productsApi,
  productClassesApi,
  productionincidenceclassApi,
  propertiesApi,
  propertyclassesApi,
  statesApi,
  unitsApi,
  unitclassesApi,
  updateVersionsApi,
  uploadFilesApi,
  clientApi,
  virtualStatesApi,
  filesApi,
  zonesApi,
  deviceClassApi,
  devicesApi,
  userStatesApi,
  enterprisesApi,
  groupLicensesApi,
  fileAssociationsApi,
};

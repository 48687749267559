import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { FontAwesomeIcon, faAngleLeft } from '@zerintia/powerstone-icons';
import { Button, Label, useToasts } from '@zerintia/powerstone-ui';
import { useZForm, ZForm, ZInput } from '@zerintia/powerstone-form';

import { PageContainer } from '../../components';
import { usersApi } from '../../services/managementApi';

const checkIsValidEmail = (schema, candidate) => {
  try {
    schema.validateSync(candidate);
    return true;
  } catch {
    return false;
  }
};

const ForgotPassword = ({ location }) => {
  const { username } = location.state || {};
  const { t } = useTranslation('core');
  const history = useHistory();
  const { showToast } = useToasts();
  const ForgotPasswordSchema = yup.object().shape({
    email: yup.string().email(t('INVALID_EMAIL')).required(t('REQUIRED')),
  });
  const [form] = useZForm({ validationSchema: ForgotPasswordSchema });

  const defaultEmail = checkIsValidEmail(ForgotPasswordSchema, { email: username }) ? username : '';

  const handleRecovery = async ({ email }) => {
    try {
      await usersApi.regenerateCredentialsGenerateRecoveryLink({
        generateRecoveryLinkInputDto: { username: email },
      });
      showToast('success', t('FORGOT_PASSWORD_SUCCESS'));
      history.replace({
        pathname: '/pages/login',
      });
    } catch (err) {
      showToast('error', t('OPERATION_FAILED'));
    }
  };

  return (
    <PageContainer>
      <ZForm form={form} onSubmit={handleRecovery}>
        <Label htmlFor='email'>{t('EMAIL')}</Label>
        <ZInput name='email' type='email' defaultValue={defaultEmail} placeholder={t('EMAIL')} />
        <div className='d-flex justify-content-between align-items-center mt-3'>
          <Button type='submit' color='primary' className='align-self-center'>
            {t('FORGOT_PASSWORD_RESET_PASSWORD')}
          </Button>
          <Link to='/pages/login' replace>
            <Button color='link' className='align-self-center ml-auto p-0 text-decoration-none'>
              <FontAwesomeIcon icon={faAngleLeft} /> {t('FORGOT_PASSWORD_BACK_TO_LOGIN')}
            </Button>
          </Link>
        </div>
      </ZForm>
    </PageContainer>
  );
};

ForgotPassword.defaultProps = {
  location: { state: {} },
};

ForgotPassword.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({ username: PropTypes.string }),
  }),
};

export default ForgotPassword;

import * as React from 'react';
import { useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';

import LoginForm from './components/LoginForm';
import { PageLoader, useToasts } from '@zerintia/powerstone-ui';
import { ErrorBanner, PageContainer } from '../../components';
import { useSession } from '../../hooks/useSession';
import { authApi } from '../../services/managementApi';
import { sessionStorageService } from '@zerintia/powerstone-auth';
import environments from 'environments';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login = () => {
  const { t } = useTranslation();
  const query = useQuery();
  const history = useHistory();
  const { showToast } = useToasts();
  const { activeSession, loading: loadingSession, startSession } = useSession();
  const [initLoading, setInitLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [macState, setMac] = React.useState(undefined);
  const [initError, setInitError] = React.useState(false);
  const [typesLogin, setTypesLogin] = React.useState();
  const [logged, setLogged] = React.useState(false);
  const saml_login_url = `${environments.MANAGEMENT_BASE_URL}/zf/api/auth/sso/saml/login`;

  const login = async () => {
    await authApi.authenticationGetEndpoints();
    const userinfoResponse = await authApi.authenticationGetUserInfo();

    setLogged(userinfoResponse.data);
  };

  React.useEffect(() => {
    async function getTypesLogin() {
      setInitLoading(true);
      await startSession();
      try {
        const { data } = await authApi.authenticationTypesLogin({
          client: 'web',
        });
        sessionStorageService.setLoginTypes(data);
        const mac = await window.zerintia?.getMAC();
        setMac(mac);
        const macLogin = async (mac) => {
          try {
            await authApi.authenticationLoginDevice({
              loginDeviceInputDto: {
                client: 'web',
                mac,
              },
            });
            await login();
          } catch {
            setTypesLogin(data);
          }
        };
        if (data?.device?.mac && mac) {
          await macLogin(mac);
        } else {
          setTypesLogin(data);
        }
      } catch (err) {
        console.error(err);
        setInitError(err);
      } finally {
        setInitLoading(false);
      }
    }
    if (activeSession && !loadingSession) {
      history.replace({
        pathname: '/pages/application-catalog',
      });
    } else if (query.get('saml') === 'true') {
      login();
    } else {
      getTypesLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogin = async ({ username, password, qr }) => {
    if (qr === null) {
      return;
    }
    setLoading(true);
    try {
      if (qr === undefined) {
        await authApi.authenticationLogin({
          loginInputDto: { username, password },
        });
      } else {
        await authApi.authenticationQrLogin({
          qRLoginInputDto: { apiKey: qr },
        });
      }
      await login();
    } catch (error) {
      showToast('error', t('ERROR_ON_LOGIN'));
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (logged) {
      history.push({
        pathname: '/pages/session-profiles',
        state: { user: logged, redirect_uri: query.get('redirect_uri') },
      });
    }
  }, [logged, history, query]);

  if (initLoading) {
    return (
      <PageContainer>
        <PageLoader />
      </PageContainer>
    );
  }

  if (initError) {
    return (
      <PageContainer>
        <ErrorBanner />
      </PageContainer>
    );
  }

  if (typesLogin?.saml_oauth) {
    return (
      <PageContainer>
        <LoginForm
          typesLogin={typesLogin}
          onLogin={handleLogin}
          loading={loading}
        />
        <a id='' className='btn btn-primary btn-block' href={saml_login_url}>
          {t('LOGIN_SAML')}
        </a>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      {typesLogin?.device?.mac && macState && (
        <p className='text-center'>MAC: {macState.toUpperCase()}</p>
      )}
      <LoginForm
        typesLogin={typesLogin}
        onLogin={handleLogin}
        loading={loading}
      />
    </PageContainer>
  );
};

Login.propTypes = {};

export default Login;

import {
  ActionLogo,
  ManufacturingLogo,
  ProcessLogo,
  RemoteLogo,
  ManagementLogo,
  LogisticLogo,
  SafetyLogo,
  AfterSalesLogo,
  RulesEngineLogo,
} from '@zerintia/powerstone-auth';
import environments from 'environments';

export const Apps = {
  Management: {
    name: 'Management',
    color: '#b4c3d6',
    backgroundColor: '#98a4b2',
    colors: ['#b4c3d6', '#98a4b2', '#c3d3e5', '#6f7e8b'],
    logo: <ManagementLogo />,
    environment: environments.MANAGEMENT_FRONT,
  },
  '4Action': {
    name: '4Action',
    color: '#e44124',
    backgroundColor: '#d02319',
    colors: ['#e44124', '#d02319', '#ff7b6f', '#a30000'],
    logo: <ActionLogo />,
    environment: environments.ACTION_FRONT,
  },
  '4Logistic': {
    name: '4Logistic',
    color: '#869ba5',
    backgroundColor: '#6d8290',
    colors: ['#869ba5', '#6d8290', '#a9c0cd', '#4b5e6a'],
    logo: <LogisticLogo />,
    environment: environments.LOGISTIC_FRONT,
  },
  '4Manufacturing': {
    name: '4Manufacturing',
    color: '#f9b333',
    backgroundColor: '#f39204',
    colors: ['#f9b333', '#f39204', '#ffc266', '#c66a00'],
    logo: <ManufacturingLogo />,
    environment: environments.MANUFACTURING_FRONT,
  },
  '4Process': {
    name: '4Process',
    color: '#45b068',
    backgroundColor: '#28a950',
    colors: ['#45b068', '#28a950', '#7dd9a0', '#00793b'],
    logo: <ProcessLogo />,
    environment: environments.PROCESS_FRONT,
  },
  '4Remote': {
    name: '4Remote',
    color: '#46a7ce',
    backgroundColor: '#1f96c2',
    colors: ['#46a7ce', '#1f96c2', '#7dd9ff', '#00789d'],
    logo: <RemoteLogo />,
    environment: environments.REMOTE_FRONT,
  },
  '4Safety': {
    name: '4Safety',
    color: '#85529c',
    backgroundColor: '#69338a',
    colors: ['#85529c', '#69338a', '#b17fc9', '#4d1a6d'],
    logo: <SafetyLogo />,
    environment: environments.SAFETY_FRONT,
  },
  '4AfterSales': {
    name: '4AfterSales',
    color: '#3e66af',
    backgroundColor: '#2f5291',
    colors: ['#3e66af', '#2f5291', '#6d8ccf', '#1e3d6e'],
    logo: <AfterSalesLogo />,
    environment: environments.AFTERSALES_FRONT,
  },
  'RulesEngine': {
    name: 'Rules engine',
    color: '#8598F7',
    backgroundColor: '#6172D4',
    colors: ['#8598F7', '#6172D4', '#A8B4F7', '#3A4C9C'],
    logo: <RulesEngineLogo />,
    environment: environments.ACTION_FRONT,
  },
};

export const languages = [
  { code: 'es', flag: 'ES' },
  { code: 'en', flag: 'GB' },
];

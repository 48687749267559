import React from 'react';
import ReactDOM from 'react-dom';

import '@zerintia/powerstone-ui/dist/powerstone-ui.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

const DefaultIcon = L.icon({
  iconAnchor: [10, 41],
  iconSize: [25, 41],
  iconUrl: icon,
  popupAnchor: [2, -40],
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

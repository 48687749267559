import React from 'react';
import PropTypes from 'prop-types';
import { HeaderAuth } from '..';

import style from './PageContainer.module.css';
import { EmptyLayout, EmptyLayoutSection } from '@zerintia/powerstone-ui';

const PageContainer = ({ children }) => {
  return (
    <EmptyLayout>
      <EmptyLayoutSection center>
        <div className={style.pageContainer}>
          <HeaderAuth />
          {children}
        </div>
      </EmptyLayoutSection>
    </EmptyLayout>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node,
};

export default PageContainer;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CardTitle } from '@zerintia/powerstone-ui';

import { Consumer } from './context';
import classes from './AccordionHeader.module.css';

export const AccordionHeader = (props) => (
  <Consumer>
    {({ onToggle }) => (
      <CardTitle className={classNames(props.className, classes.header)} onClick={onToggle}>
        {props.children}
      </CardTitle>
    )}
  </Consumer>
);
AccordionHeader.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

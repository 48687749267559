import { apiConfig } from '@zerintia/powerstone-auth';
import environments from '../environments';

const instance = apiConfig.init(environments.MANAGEMENT_FRONT);

instance.defaults.baseURL = environments.MANAGEMENT_BASE_URL;

const zerintiaLicensesUrl = `${environments.MANAGEMENT_BASE_URL}/zf/api/zerintia_licenses`;
const customerLicensesUrl = `${environments.MANAGEMENT_BASE_URL}/zf/api/customer_licenses`;
const licensesManagementUrl = `${environments.MANAGEMENT_BASE_URL}/zf/api/licenses_management`;

function getAllowedApps(){
  return instance.get(`${zerintiaLicensesUrl}/allowed_apps`);
}

function getZerintiaLicenses(params) {
  return instance.get(`${zerintiaLicensesUrl}`, {
    params: params,
  });
}
function getCustomerLicenses(params) {
  return instance.get(`${customerLicensesUrl}`, {
    params: params,
  });
}

function postCustomerLicense(product, body, params) {
  return instance.post(`${licensesManagementUrl}/${product}`, body, {
    params,
  });
}

function putCustomerLicense(product, body, params) {
  return instance.put(
    `${licensesManagementUrl}/${product}/number_of_licenses`,
    body,
    { params }
  );
}

function postActivateLicense(product, body, params) {
  return instance.post(
    `${licensesManagementUrl}/${product}/active_licenses`,
    body,
    { params }
  );
}

function deleteDeactivateLicense(product, body, params) {
  return instance.delete(
    `${licensesManagementUrl}/${product}/active_licenses`,
    { params, data: body }
  );
}

export {
  getZerintiaLicenses,
  getCustomerLicenses,
  postCustomerLicense,
  putCustomerLicense,
  postActivateLicense,
  deleteDeactivateLicense,
  getAllowedApps
};

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import RegularPolygonInstanceHelper from './RegularPolygonInstanceHelper';
import { FIGURES_3D, MAX_VERTICES, MIN_VERTICES } from '../config';

const RegularPolygonInstance = ({ polygons, ratio, smooth }) => {
  const polygonsByVertices = useMemo(
    () =>
      polygons.reduce((prev, curr) => {
        const vertices =
          curr.type === FIGURES_3D.cylinder
            ? 3 * MAX_VERTICES
            : Math.min(
              Math.max(curr.vertex_number, MIN_VERTICES),
              MAX_VERTICES
            );
        return {
          ...prev,
          [vertices]: [...(prev[vertices] || []), curr],
        };
      }, {}),
    [polygons]
  );

  return Object.entries(polygonsByVertices).map(
    ([vertex_number, polygonsByVertex], i) => (
      <RegularPolygonInstanceHelper
        key={i}
        polygons={polygonsByVertex}
        ratio={ratio}
        vertex_number={Number(vertex_number)}
        smooth={smooth}
      />
    )
  );
};

RegularPolygonInstance.propTypes = {
  polygons: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      onClick: PropTypes.func,
      position: PropTypes.shape({
        x: PropTypes.number.isRequired, // m
        y: PropTypes.number.isRequired, // m
        z: PropTypes.number.isRequired, // m
      }),
      radius: PropTypes.number, // m
      rotation: PropTypes.shape({
        x: PropTypes.number.isRequired, // rad
        y: PropTypes.number.isRequired, // rad
        z: PropTypes.number.isRequired, // rad
      }),
      scale: PropTypes.shape({
        z: PropTypes.number.isRequired, // m
      }).isRequired,
      vertex_number: PropTypes.number,
      text: PropTypes.string,
    })
  ).isRequired,
  ratio: PropTypes.number,
  smooth: PropTypes.bool,
};

export default RegularPolygonInstance;

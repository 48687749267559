import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUserSessionState } from '@zerintia/powerstone-auth';
import {
  ForgotPassword,
  Login,
  ApplicationCatalog,
  RecoveryPassword,
} from '../publicRoutes';
import { PrivateRoute } from '../components';
import { useSession } from '../hooks/useSession';
import SessionProfiles from './session-profiles';
import routesMatch from './routesMatch';

const buildRoutes = (routes = []) => {
  return routes.map((item) => {
    if (item.to || item.route) {
      const foundRoute = routesMatch.find(
        ({ path }) => path === item.to || path === item.route,
      );

      if (!foundRoute) {
        throw new Error(
          `ZerintiaError :: ${
            item.to || item.route
          } route is not declared on routesMatch`,
        );
      }

      const { component, name } = foundRoute;

      return (
        <PrivateRoute
          path={item.to ? item.to : item.route}
          key={item.to ? item.to : item.route}
          exact
          component={component}
          resources={item.resources}
          routesMatch={routesMatch}
          name={name}
        />
      );
    } else if (item.children) {
      return buildRoutes(item.children);
    } else {
      return undefined;
    }
  });
};

function Routes() {
  const { activeSession } = useSession();
  const { t } = useTranslation();
  const { resources } = useUserSessionState();

  const routes = React.useMemo(() => buildRoutes(resources), [resources]);

  return (
    <React.Suspense fallback={<div>{t('LOADING_PAGE')}</div>}>
      <Switch>
        <Redirect
          from='/'
          to={activeSession ? '/dashboard-licenses' : '/pages/login'}
          exact
        />
        <Route path='/pages/login' component={Login} exact />
        <Route path='/pages/forgot-password' component={ForgotPassword} exact />
        <Route
          path='/recovery-password/:hashCode'
          component={RecoveryPassword}
          exact
        />
        <Route
          path='/pages/session-profiles'
          component={SessionProfiles}
          exact
        />
        <Route
          path='/pages/application-catalog'
          component={ApplicationCatalog}
          exact
        />
        {routes}
      </Switch>
    </React.Suspense>
  );
}

export default Routes;

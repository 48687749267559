import { useLoader } from '@react-three/fiber';
import PropTypes from 'prop-types';
import React from 'react';
import * as THREE from 'three';
import { BACKGROUND_COLOR } from '../config';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.info('error', 'TEXTURE_LOAD_FAILED');
    console.info(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <PlaneMesh {...this.props} image={undefined} />
    }

    return this.props.children; 
  }
}

const PlaneMesh = ({ height, image, origin, ratio, width }) => {
  let texture;
  if (image) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    texture = useLoader(THREE.TextureLoader, image);
  }
  
  return (
    <mesh
      position={[
        (width / 2 - origin.x) / ratio,
        -0.02,
        (height / 2 - origin.y) / ratio,
      ]}
      rotation={[-Math.PI / 2, 0, 0]}
      scale={1}
    >
      <planeGeometry args={[width / ratio, height / ratio]} />
      {
        texture
          ?
          <meshStandardMaterial map={texture} side={THREE.DoubleSide} />
          : 
          <meshStandardMaterial
            color={BACKGROUND_COLOR}
            side={THREE.DoubleSide}
          />
      }
    </mesh>
  );
};

const Plane = (props) => {
  return (
    <ErrorBoundary {...props}>
      <PlaneMesh {...props}/>
    </ErrorBoundary>
  )
}

Plane.propTypes = {
  height: PropTypes.number, // px
  image: PropTypes.string,
  origin: PropTypes.shape({
    x: PropTypes.number.isRequired, // px
    y: PropTypes.number.isRequired, // px
  }).isRequired,
  ratio: PropTypes.number,
  width: PropTypes.number, // px
};

export default Plane;

const environments = {
  MANAGEMENT_BASE_URL: window._env_.MANAGEMENT_BASE_URL,
  MANUFACTURING_FRONT: window._env_.MANUFACTURING_FRONT,
  REMOTE_FRONT: window._env_.REMOTE_FRONT,
  ACTION_FRONT: window._env_.ACTION_FRONT,
  PROCESS_FRONT: window._env_.PROCESS_FRONT,
  SAFETY_FRONT: window._env_.SAFETY_FRONT,
  LOGISTIC_FRONT: window._env_.LOGISTIC_FRONT,
  AFTERSALES_FRONT: window._env_.AFTERSALES_FRONT,
  SHOW_QR_ZID: window._env_.SHOW_QR_ZID,
};

export default environments;

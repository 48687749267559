import { useCallback, useReducer } from 'react';
import {
  sessionStorageService,
  useUserSessionUpdater,
} from '@zerintia/powerstone-auth';
import { authApi, sitesApi, userStatesApi } from 'services/managementApi';

function reducer(state, action) {
  switch (action.type) {
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setError':
      return { ...state, error: action.payload };
    case 'setSession':
      return { ...state, activeSession: action.payload };
    case 'setSuccess':
      return { ...state, success: action.payload };
    case 'update':
      return { ...state, ...action.payload };
    default:
      throw new Error(`${action.type} action no supported`);
  }
}

function useSession() {
  const userSessionDispatch = useUserSessionUpdater();
  const [state, dispatch] = useReducer(reducer, {
    loading: true,
    error: null,
    activeSession: true,
    success: false,
  });

  const startSession = useCallback(async () => {
    const desactiveSession = () => {
      dispatch({ type: 'setSession', payload: false });
      dispatch({ type: 'setLoading', payload: false });
    };
    try {
      const loginTypes = sessionStorageService.getLoginTypes();

      if (!loginTypes) {
        const response = await authApi.authenticationTypesLogin({
          client: 'web',
        });
        sessionStorageService.setLoginTypes(response.data);
      }

      let userInfo;
      try {
        const userInfoResponse = await authApi.authenticationGetUserInfo();
        userInfo = userInfoResponse.data;
      } catch (e) {
        return desactiveSession();
      }

      if (!userInfo?.session) {
        return desactiveSession();
      }

      const availableSites = userInfo.session.attributes?.sites;
      const activeSites = userInfo.session.attributes?.active_sites || [];
      let currentSites;

      if (availableSites?.length === 0) {
        currentSites = await sitesApi.sitesFindAll({
          sort: 'name',
          limit: 10_000,
        });
      } else {
        currentSites = await sitesApi.sitesFindAll({
          sort: 'name',
          limit: 10_000,
          filters: ['_id'],
          values: [`|${availableSites.join(',')}`],
        });
      }
      const userMenuResponse = await authApi.authenticationGetUserMenu();

      const userState = await userStatesApi.userStateFindUserState({
        id: userInfo._id,
      });

      userSessionDispatch({
        type: 'update',
        payload: {
          user: userInfo,
          profileId: userInfo.session.profile,
          sites: currentSites.data.items.map((site) => ({
            ...site,
            selected:
              activeSites.length === 0 || activeSites.includes(site._id),
          })),
          resources: userMenuResponse.data,
          privileges: userInfo.session.privileges,
          activeSession: true,
          changePassword: userState.data.change_password,
        },
      });

      dispatch({
        type: 'update',
        payload: {
          activeSession: true,
          success: true,
          loading: false,
        },
      });
    } catch (err) {
      sessionStorage.clear();
      console.error(err);
      dispatch({
        type: 'update',
        payload: {
          loading: false,
          error: err,
        },
      });
    }
  }, [userSessionDispatch]);

  return {
    startSession,
    loading: state.loading,
    error: state.error,
    activeSession: state.activeSession,
    success: state.success,
  };
}

export { useSession };

import { Button, Col, Row } from '@zerintia/powerstone-ui';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

const ViewQR = ({ qr, showCode }) => {
  const { t } = useTranslation('translations');
  const linkRef = useRef(null);

  useEffect(() => {
    setQRUrlToLink();
  }, []);

  const setQRUrlToLink = () => {
    const canvas = document.querySelector('.HpQrcode > canvas');
    linkRef.current.href = canvas.toDataURL();
    linkRef.current.download = 'QR_code.png';
  };
  const downloadApikey = () => {
    linkRef.current.click();
  };

  return (
    <Col>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <div className='full-content-width HpQrcode'>
          <QRCode
            className='full-content-width pointer'
            id='qrcode'
            onClick={downloadApikey}
            size={400}
            style={{ cursor: 'pointer' }}
            value={qr || '-'}
          />
          {showCode && (
            <h2 className='mt-2'>{qr || '-'}</h2>
          )}
        </div>
      </Row>
      <Row>
        <div className='ml-auto'>
          <a download target='_self' href='_self' ref={linkRef}>
            <Button color='secondary' className='ml-3'>
              {t('DOWNLOAD')}
            </Button>
          </a>
        </div>
      </Row>
    </Col>
  );
};

export default ViewQR;

ViewQR.propTypes = {
  onSubmit: PropTypes.func,
  unit: PropTypes.object,
};

import * as React from 'react';
import PropTypes from 'prop-types';

import style from './HeaderAuth.module.css';

function HeaderAuth({ width }) {
  const sizeProps = width
    ? {
      width,
    }
    : {};
  return (
    <div className={style.imgContainer}>
      <img
        className={!width ? style.image : ''}
        src='/images/logo-primary.png'
        alt='Enterprise Logo'
        {...sizeProps}
      />
    </div>
  );
}

HeaderAuth.propTypes = {
  width: PropTypes.number,
};

export default HeaderAuth;

import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
} from '@zerintia/powerstone-ui';
import PropTypes from 'prop-types';

const DetailsCard = ({
  title,
  description,
  children,
  className,
  classNameCard,
  notitle,
}) => {
  return (
    <Card className={classNameCard}>
      {(notitle === undefined || notitle === false) && (
        <CardBody className='bg-light border-bottom'>
          <CardTitle tag='h6' className='m-0'>
            {title}
          </CardTitle>
          <CardSubtitle>{description}</CardSubtitle>
        </CardBody>
      )}
      <CardBody className={className}>{children}</CardBody>
    </Card>
  );
};

DetailsCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
};

export default DetailsCard;

import * as React from 'react';

const routesMatch = [
  {
    path: '/users/internal',
    name: 'USERS',
    component: React.lazy(() => import('./users/internal-users')),
  },
  {
    path: '/users/internal/edit',
    name: 'USERS_FORM',
    component: React.lazy(() =>
      import('./users/internal-users/components/UserForm'),
    ),
  },
  {
    path: '/users/internal/associated-documents',
    name: 'ASSOCIATED_DOCUMENTS',
    component: React.lazy(() =>
      import('./components/AssociatedDocuments'),
    ),
  },
  {
    path: '/users/external',
    name: 'EXTERNAL_USERS',
    component: React.lazy(() => import('./users/external-users')),
  },
  {
    path: '/users/external/edit',
    name: 'EXTERNAL_USERS_FORM',
    component: React.lazy(() =>
      import('./users/external-users/components/ExternalUserForm'),
    ),
  },
  {
    path: '/users/webinar',
    name: 'WEBINAR_USERS',
    component: React.lazy(() => import('./users/webinar-users')),
  },
  {
    path: '/users/webinar/edit',
    name: 'WEBINAR_USERS_FORM',
    component: React.lazy(() =>
      import('./users/webinar-users/components/WebinarUserForm'),
    ),
  },
  {
    path: '/users/webinar/multiple-creation',
    name: 'MULTIPLE_CREATION',
    component: React.lazy(() =>
      import('./users/webinar-users/components/MultipleCreationForm'),
    ),
  },
  {
    path: '/customers',
    name: 'CUSTOMERS',
    component: React.lazy(() => import('./customers')),
  },
  {
    path: '/customers/edit',
    name: 'CUSTOMERS_FORM',
    component: React.lazy(() => import('./customers/components/CustomerForm')),
  },
  {
    path: '/customers/associated-documents',
    name: 'ASSOCIATED_DOCUMENTS',
    component: React.lazy(() =>
      import('./components/AssociatedDocuments'),
    ),
  },
  {
    path: '/configuration/roles',
    name: 'ROLES',
    component: React.lazy(() => import('./configuration/roles')),
  },
  {
    path: '/configuration/resources',
    name: 'RESOURCES',
    component: React.lazy(() => import('./configuration/resources')),
  },
  {
    path: '/configuration/resources/edit',
    name: 'RESOURCES_FORM',
    component: React.lazy(() =>
      import('./configuration/resources/components/ResourceForm'),
    ),
  },
  {
    path: '/products',
    name: 'PRODUCTS',
    component: React.lazy(() => import('./products')),
  },
  {
    component: React.lazy(() =>
      import('./plants/properties/components/PropertiesForm'),
    ),
    name: 'PRODUCT_PROPERTIES',
    path: '/products/properties',
  },
  {
    path: '/products/edit',
    name: 'PRODUCTS_FORM',
    component: React.lazy(() => import('./products/components/ProductForm')),
  },
  {
    path: '/products/associated-documents',
    name: 'ASSOCIATED_DOCUMENTS',
    component: React.lazy(() => import('./components/AssociatedDocuments')),
  },
  {
    path: '/product-classes',
    name: 'PRODUCTCLASSES',
    component: React.lazy(() => import('./product-classes')),
  },
  {
    path: '/product-classes/edit',
    name: 'PRODUCTCLASSES_FORM',
    component: React.lazy(() =>
      import('./product-classes/components/ProductClassForm'),
    ),
  },
  {
    component: React.lazy(() =>
      import('./plants/property-classes/components/PropertyClassesForm'),
    ),
    name: 'PROPERTY_FORM',
    path: '/product-classes/edit/properties/edit',
  },
  {
    path: '/product-classes/associated-documents',
    name: 'ASSOCIATED_DOCUMENTS',
    component: React.lazy(() => import('./components/AssociatedDocuments')),
  },
  {
    path: '/categories/machines',
    name: 'PRODUCT_FAMILY',
    component: React.lazy(() => import('./categories/machine-types')),
  },
  {
    path: '/categories/machines/edit',
    name: 'PRODUCT_FAMILY_FORM',
    component: React.lazy(() =>
      import('./categories/machine-types/components/MachineTypesForm'),
    ),
  },
  {
    path: '/categories/jobtitles',
    name: 'JOB_TITLES',
    component: React.lazy(() => import('./categories/job-titles')),
  },
  {
    path: '/categories/jobtitles/edit',
    name: 'JOB_TITLES_FORM',
    component: React.lazy(() =>
      import('./categories/job-titles/components/JobTitlesForm'),
    ),
  },
  {
    path: '/categories/user-groups',
    name: 'USER_GROUPS',
    component: React.lazy(() => import('./categories/user-groups')),
  },
  {
    path: '/categories/webinar-groups',
    name: 'WEBINAR_GROUPS',
    component: React.lazy(() => import('./categories/webinar-groups')),
  },
  {
    path: '/categories/user-groups/edit',
    name: 'USER_GROUPS_FORM',
    component: React.lazy(() =>
      import('./categories/user-groups/components/UsersGroupForm'),
    ),
  },
  {
    path: '/categories/webinar-groups/edit',
    name: 'WEBINAR_GROUPS_FORM',
    component: React.lazy(() =>
      import('./categories/webinar-groups/components/WebinarGroupForm'),
    ),
  },
  {
    component: React.lazy(() => import('./plants/area-classes')),
    name: 'AREA_CLASSES',
    path: '/plant/area-classes',
  },
  {
    component: React.lazy(() =>
      import('./plants/area-classes/components/AreaClassEditor'),
    ),
    name: 'AREA_CLASS_EDITOR',
    path: '/plant/area-classes/edit',
  },
  {
    component: React.lazy(() =>
      import('./plants/property-classes/components/PropertyClassesForm'),
    ),
    name: 'PROPERTY_FORM',
    path: '/plant/area-classes/edit/properties/edit',
  },
  {
    component: React.lazy(() => import('./plants/areas')),
    name: 'AREAS',
    path: '/plant/areas',
  },
  {
    component: React.lazy(() => import('./plants/areas/components/AreaForm')),
    name: 'AREAS_FORM',
    path: '/plant/areas/edit',
  },
  {
    component: React.lazy(() => import('./plants/areas/zones')),
    name: 'ZONES',
    path: '/plant/areas/zones',
  },
  {
    component: React.lazy(() =>
      import('./plants/areas/zones/components/ZoneForm'),
    ),
    name: 'ZONES_FORM',
    path: '/plant/areas/zones/edit',
  },
  {
    component: React.lazy(() => import('./plants/units')),
    name: 'UNITS',
    path: '/plant/units',
  },
  {
    component: React.lazy(() => import('./plants/units/components/UnitForm')),
    name: 'UNITS_FORM',
    path: '/plant/units/edit',
  },
  {
    component: React.lazy(() =>
      import('./plants/properties/components/PropertiesForm'),
    ),
    name: 'UNIT_PROPERTIES',
    path: '/plant/units/properties',
  },
  {
    component: React.lazy(() =>
      import('./plants/units/components/Multiple3DEdit'),
    ),
    name: 'MULTIPLE_3D_EDIT',
    path: '/plant/units/multiple3dedit',
  },
  {
    component: React.lazy(() => import('./plants/units/components/UnitStates')),
    name: 'STATES',
    path: '/plant/units/states',
  },
  {
    component: React.lazy(() => import('./plants/unit-classes')),
    name: 'UNIT_CLASS',
    path: '/plant/unitclasses',
  },
  {
    component: React.lazy(() =>
      import('./plants/unit-classes/components/UnitClassForm'),
    ),
    name: 'UNIT_CLASS_FORM',
    path: '/plant/unitclasses/edit',
  },
  {
    component: React.lazy(() =>
      import('./plants/property-classes/components/PropertyClassesForm'),
    ),
    name: 'PROPERTY_FORM',
    path: '/plant/unitclasses/edit/properties/edit',
  },
  {
    component: React.lazy(() => import('./plants/properties')),
    name: 'PROPERTIES',
    path: '/plant/properties',
  },
  {
    component: React.lazy(() => import('./plants/property-classes')),
    name: 'PROPERTY_CLASSES',
    path: '/plant/propertyclasses',
  },
  {
    component: React.lazy(() => import('./plants/states')),
    name: 'STATES',
    path: '/plant/states',
  },
  {
    component: React.lazy(() =>
      import('./plants/states/components/StatesForm'),
    ),
    name: 'STATES_FORM',
    path: '/plant/states/edit',
  },
  {
    component: React.lazy(() => import('./plants/visual-states')),
    name: 'VISUAL_STATES',
    path: '/plant/visual-states',
  },
  {
    component: React.lazy(() =>
      import('./plants/visual-states/components/VisualStateForm'),
    ),
    name: 'VISUAL_STATES_FORM',
    path: '/plant/visual-states/edit',
  },
  {
    component: React.lazy(() => import('./dashboard-licenses')),
    name: 'DASHBOARD_LICENSES',
    path: '/dashboard-licenses',
  },
  {
    component: React.lazy(() => import('./devices')),
    name: 'DEVICES',
    path: '/devices',
  },
  {
    component: React.lazy(() => import('./devices/components/DeviceForm')),
    name: 'DEVICES',
    path: '/devices/edit',
  },
  {
    path: '/devices/associated-documents',
    name: 'ASSOCIATED_DOCUMENTS',
    component: React.lazy(() =>
      import('./components/AssociatedDocuments'),
    ),
  },
  {
    component: React.lazy(() => import('./device-classes')),
    name: 'DEVICE_CLASSES',
    path: '/device-classes',
  },
  {
    component: React.lazy(() =>
      import('./device-classes/components/DeviceClassForm'),
    ),
    name: 'DEVICE_CLASSES_FORM',
    path: '/device-classes/edit',
  },
  {
    path: '/ota/4remote',
    name: 'OTA_4REMOTE',
    component: React.lazy(() => import('./ota/4remote')),
  },
  {
    path: '/ota/4process',
    name: 'OTA_4PROCESS',
    component: React.lazy(() => import('./ota/4process')),
  },
  {
    path: '/ota/4safety',
    name: 'OTA_4SAFETY',
    component: React.lazy(() => import('./ota/4safety')),
  },
  {
    path: '/ota/4action',
    name: 'OTA_4ACTION',
    component: React.lazy(() => import('./ota/4action')),
  },
  {
    path: '/ota/4action-tablet',
    name: 'OTA_4ACTION_TABLET',
    component: React.lazy(() => import('./ota/4action-tablet')),
  },
  {
    path: '/ota/edit',
    name: 'OTA_FORM',
    component: React.lazy(() => import('./ota/components/OtaForm')),
  },
  {
    path: '/enterprise',
    name: 'ENTERPRISE',
    component: React.lazy(() => import('./enterprise')),
  },
  {
    path: '/enterprise/site/edit',
    name: 'SITE',
    component: React.lazy(() => import('./enterprise/components/SiteForm')),
  },
  {
    path: '/enterprise/edit',
    name: 'ENTERPRISE',
    component: React.lazy(() =>
      import('./enterprise/components/EnterpriseForm'),
    ),
  },
];

export default routesMatch;

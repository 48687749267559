import { useThree } from '@react-three/fiber';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import * as THREE from 'three';
import { mergeBufferGeometries } from 'three-stdlib';

import Roboto from '../Roboto.json';
import { FIGURES_3D, TEXT_COLOR } from '../config';
import { changeAspect } from '../utils';

const TextMerged = ({ ratio, texts }) => {
  const {
    viewport: { factor },
  } = useThree();

  const getSize = (geometry) => {
    const positionArray = geometry.attributes.position.array;
    const verticesX = positionArray.filter((_, i) => i % 3 === 0);
    const verticesY = positionArray.filter((_, i) => i % 3 === 1);
    const height = Math.max(...verticesY) - Math.min(...verticesY);
    const width = Math.max(...verticesX) - Math.min(...verticesX);
    return { height, width };
  };

  const setTranslate = useCallback(
    (model, height, width) => {
      let position = [];
      switch (model.type) {
        case FIGURES_3D.belt:
        case FIGURES_3D.cube:
          position = [
            changeAspect(model.position?.x || 0, factor, ratio),
            changeAspect(model.position?.z || 0, factor, ratio),
            changeAspect(
              model.scale.y / 2 + (model.position?.y || 0),
              factor,
              ratio
            ),
          ];
          break;
        case FIGURES_3D.cylinder:
        case FIGURES_3D.regular_polygon:
          position = [
            changeAspect(model.position?.x || 0, factor, ratio),
            changeAspect(model.position?.z || 0, factor, ratio),
            changeAspect(
              model.radius + (model.position?.y || 0),
              factor,
              ratio
            ),
          ];
          break;
        default:
          position = [0, 0, 0];
          break;
      }
      return [
        position[0] - changeAspect(width) / 2,
        position[1],
        position[2] + changeAspect(2 * height),
      ];
    },
    [factor, ratio]
  );

  const textGeometries = useMemo(() => {
    const font = new THREE.FontLoader().parse(Roboto);
    const geometries = texts.map(({ text, ...model }) => {
      const textGeometry = new THREE.TextBufferGeometry(text, {
        font,
        height: 0,
        size: changeAspect(0.2, factor, ratio),
      });
      const { height, width } = getSize(textGeometry);
      textGeometry.rotateX(-Math.PI / 2);
      textGeometry.translate(...setTranslate(model, height, width));
      return textGeometry;
    });
    return mergeBufferGeometries(geometries);
  }, [factor, ratio, setTranslate, texts]);

  return (
    <mesh geometry={textGeometries}>
      <meshBasicMaterial color={TEXT_COLOR} />
    </mesh>
  );
};

TextMerged.propTypes = {
  ratio: PropTypes.number,
  texts: PropTypes.arrayOf(
    PropTypes.shape({
      position: PropTypes.shape({
        x: PropTypes.number, // m
        y: PropTypes.number, // m
        z: PropTypes.number, // m
      }),
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TextMerged;

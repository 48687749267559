import { useFrame, useThree } from '@react-three/fiber';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import * as THREE from 'three';

import { EDITING_COLOR } from '../config';
import { changeAspect } from '../utils';

const dummy = new THREE.Object3D();

const BeltInstance = ({ belts, ratio }) => {
  const intersection = useRef();
  const meshRef = useRef();

  const mousedown = useRef(false);
  const mousedownPos = useRef({ x: 0, y: 0 });
  const dragging = useRef(false);

  const {
    viewport: { factor },
  } = useThree();

  useFrame(({ raycaster }) => {
    intersection.current = raycaster.intersectObject(meshRef.current)?.[0];
  });

  const beltShape = useMemo(() => {
    /**
     *    /------\    --
     *   /        \   |
     *   \        /   | h
     *    \______/    |_
     *
     *    |------|
     *        s
     *
     *  |----------|
     *        w
     *
     */
    const h = 1;
    const w = 1;
    const s = w - 0.5;

    const shape = new THREE.Shape();
    shape.moveTo(-w / 2, h / 2);
    shape.lineTo(-s / 2, 0);
    shape.lineTo(-s / 2, 0);
    shape.lineTo(s / 2, 0);
    shape.lineTo(w / 2, h / 2);
    shape.lineTo(s / 2, h);
    shape.lineTo(-s / 2, h);

    return shape;
  }, []);

  useEffect(() => {
    const onMouseDown = (e) => {
      mousedown.current = true;
      mousedownPos.current = {
        x: e.clientX,
        y: e.clientY,
      };
    }
    const onMouseMove = (e) => {
      if (!dragging.current) {
        dragging.current = mousedown.current && (Math.abs(mousedownPos.current.x - e.clientX) > 2 || Math.abs(mousedownPos.current.y - e.clientY) > 2);
      }
    }
    const onMouseUp = () => {
      mousedown.current = false;
      if (dragging.current) {
        dragging.current = false;
        return;
      }
      belts[intersection.current?.instanceId]?.onClick?.();
    }
    window.addEventListener('mousedown', onMouseDown);
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
    return () => {
      window.removeEventListener('mousedown', onMouseDown);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    }
  }, [belts]);

  useEffect(() => {
    belts.forEach(({ color, position, rotation, scale }, i) => {
      dummy.position.set(
        changeAspect(position?.x || 0, factor, ratio),
        changeAspect(position?.z || 0, factor, ratio),
        changeAspect(-scale.y / 2 + (position?.y || 0), factor, ratio)
      );
      dummy.rotation.set(rotation?.x || 0, rotation?.z || 0, rotation?.y || 0);
      dummy.scale.set(
        changeAspect(scale.x, factor, ratio),
        changeAspect(scale.z, factor, ratio),
        changeAspect(scale.y, factor, ratio)
      );
      dummy.updateMatrix();
      meshRef.current.setMatrixAt(i, dummy.matrix);
      meshRef.current.setColorAt(i, new THREE.Color(color || EDITING_COLOR));
      meshRef.current.instanceColor.needsUpdate = true;
    });
    meshRef.current.instanceMatrix.needsUpdate = true;
  }, [belts, factor, ratio]);

  const extrudeGeometry = useMemo(
    () =>
      new THREE.ExtrudeBufferGeometry(beltShape, {
        bevelEnabled: false,
        depth: 1,
      }),
    [beltShape]
  );

  return (
    <instancedMesh args={[extrudeGeometry, null, belts.length]} ref={meshRef}>
      <meshLambertMaterial attach='material' />
    </instancedMesh>
  );
};

BeltInstance.propTypes = {
  belts: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      onClick: PropTypes.func,
      position: PropTypes.shape({
        x: PropTypes.number, // m
        y: PropTypes.number, // m
        z: PropTypes.number, // m
      }),
      rotation: PropTypes.shape({
        x: PropTypes.number.isRequired, // rad
        y: PropTypes.number.isRequired, // rad
        z: PropTypes.number.isRequired, // rad
      }),
      scale: PropTypes.shape({
        x: PropTypes.number.isRequired, // m
        y: PropTypes.number.isRequired, // m
        z: PropTypes.number.isRequired, // m
      }).isRequired,
      text: PropTypes.string,
    })
  ).isRequired,
  ratio: PropTypes.number,
};

export default BeltInstance;

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AppLayout, withZModal, ZModalDialog } from '@zerintia/powerstone-ui';
import {
  NavLanguageSelector,
  NavbarSites,
  NavbarUser,
  NavbarAppsSelector,
  useUserSessionState,
  useLogout,
  UserChangePassword,
  useUserSessionUpdater,
} from '@zerintia/powerstone-auth';
import Routes from '../routes';
import { useSession } from '../hooks/useSession';
import {
  faLock,
  faPowerOff,
  FontAwesomeIcon,
} from '@zerintia/powerstone-icons';
import { getAllowedApps } from 'services/licensesApi';
import { Apps } from 'utils';

const languages = [
  { code: 'es', flag: 'ES' },
  { code: 'en', flag: 'GB' },
];

function MainLayout({ openModal, closeModal }) {
  const { t } = useTranslation();
  const { startSession } = useSession();
  const { resources, profileId, user, changePassword } = useUserSessionState();
  const userSessionDispatch = useUserSessionUpdater();
  const logout = useLogout(window.location.origin);
  const [apps, setApss] = React.useState({});

  const handleLogout = () => {
    openModal({
      Icon: <FontAwesomeIcon icon={faPowerOff} />,
      modalTitle: t('LOGOUT'),
      component: (
        <ZModalDialog
          text={t('CONFIRM_LOGOUT')}
          onAccept={() => {
            logout();
            closeModal();
          }}
          onCancel={closeModal}
        />
      ),
    });
  };

  async function getAllowedAppsFn() {
    const allowedApps = await getAllowedApps();
    setApss(
      allowedApps?.data?.apps?.reduce((o, current) => {
        return {
          ...o,
          [current === 'Management' ? '4Management' : current]: {
            name: current,
            href:
              current === 'Management'
                ? window.location.origin
                : Apps[current]?.environment,
          },
        };
      }, {}),
    );
  }

  React.useEffect(() => {
    if (profileId) {
      getAllowedAppsFn();
    }
  }, [profileId]);

  React.useEffect(() => {
    startSession();
  }, [startSession]);

  React.useEffect(() => {
    if (changePassword && user) {
      openModal({
        modalTitle: t('CHANGE_PASSWORD'),
        Icon: <FontAwesomeIcon icon={faLock} />,
        backdrop: 'static',
        component: (
          <UserChangePassword
            forceSubmit={true}
            userId={user?.id || ''}
            onSubmit={() => {
              userSessionDispatch({
                type: 'deactiveChangePassword',
              });
              closeModal();
            }}
            defaultSubmitData={{ firstChange: true }}
          />
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changePassword, user]);

  return (
    <AppLayout
      pageTitle='Management'
      home='/users/internal'
      navbarAuthComponents={
        <>
          <NavLanguageSelector languages={languages} />
          <NavbarSites />
          {apps && <NavbarAppsSelector isLastNavbarElement appLinks={apps} />}
          <NavbarUser onLogout={handleLogout} />
        </>
      }
      resources={resources}
    >
      <Routes />
    </AppLayout>
  );
}

export default withZModal(MainLayout);

import * as React from 'react';
import QrReader from 'react-qr-reader';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import {
  FontAwesomeIcon,
  faCircleNotch,
  faQrcode,
} from '@zerintia/powerstone-icons';
import { useZForm, ZForm, ZInput } from '@zerintia/powerstone-form';
import { TableTitleButton } from '@zerintia/powerstone-table';
import { Button, FormGroup, Label, withZModal } from '@zerintia/powerstone-ui';

function LoginForm({ typesLogin, onLogin, openModal, loading }) {
  const { t } = useTranslation('core');
  const LoginSchema = yup.object().shape({
    username: yup.string().required(t('REQUIRED')),
    password: yup.string().required(t('REQUIRED')),
  });
  const [form] = useZForm({ validationSchema: LoginSchema });
  const watchusername = form.watch('username');

  const handleLogin = async ({ username, password, qr }) => {
    onLogin({ username, password, qr });
  };

  return (
    <>
      {(typesLogin?.email || typesLogin?.worker_number) &&
        (typesLogin?.Password || typesLogin?.Pin) && (
          <ZForm form={form} onSubmit={handleLogin}>
            <FormGroup>
              <Label htmlFor='username'>
                {[
                  ...(typesLogin?.email ? [t('EMAIL')] : []),
                  ...(typesLogin?.worker_number ? [t('WORKER_NUMBER')] : []),
                ].join(' / ')}
              </Label>
              <ZInput
                name='username'
                placeholder={[
                  ...(typesLogin?.email ? [t('EMAIL')] : []),
                  ...(typesLogin?.worker_number ? [t('WORKER_NUMBER')] : []),
                ].join(' / ')}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor='password'>
                {[
                  ...(typesLogin?.Password ? [t('PASSWORD')] : []),
                  ...(typesLogin?.Pin ? [t('PIN')] : []),
                ].join(' / ')}
              </Label>
              <ZInput
                name='password'
                placeholder={[
                  ...(typesLogin?.Password ? [t('PASSWORD')] : []),
                  ...(typesLogin?.Pin ? [t('PIN')] : []),
                ].join(' / ')}
                type='password'
              />
            </FormGroup>
            <Button type='submit' color='primary' block>
              {loading ? (
                <FontAwesomeIcon icon={faCircleNotch} className='fa-spin' />
              ) : (
                t('LOGIN_SIGN_IN')
              )}
            </Button>
          </ZForm>
        )}
      {typesLogin?.QR && (
        <TableTitleButton
          className='mt-4 w-100'
          icon={<FontAwesomeIcon icon={faQrcode} />}
          label={t('LOGIN_QR')}
          onClick={() =>
            openModal({
              Icon: <FontAwesomeIcon icon={faQrcode} />,
              component: <QrReader onScan={(qr) => handleLogin({ qr })} />,
              modalTitle: t('LOGIN_QR'),
            })
          }
        />
      )}
      {(typesLogin?.email || typesLogin?.worker_number) &&
        (typesLogin?.Password || typesLogin?.Pin) && (
          <div className='d-flex mt-3 justify-content-center'>
            <Link
              to={{
                pathname: '/pages/forgot-password',
                state: { username: watchusername },
              }}
            >
              {t('LOGIN_FORGOT_PASSWORD')}
            </Link>
          </div>
        )}
    </>
  );
}

LoginForm.propTypes = {
  typesLogin: PropTypes.object,
  onLogin: PropTypes.func,
  loading: PropTypes.bool,
};

export default withZModal(LoginForm);

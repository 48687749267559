import React from 'react';
import PropTypes from 'prop-types';
import { HeaderAuth, ErrorBanner } from '../../components';
import {
  EmptyLayout,
  EmptyLayoutSection,
  withZModal,
  ZModalDialog,
  useToasts,
  Row,
} from '@zerintia/powerstone-ui';
import {
  useLogout,
  NavLanguageSelector,
  NavbarUser,
  useUserSessionState,
  useApiQuery,
} from '@zerintia/powerstone-auth';
import { FontAwesomeIcon, faPowerOff } from '@zerintia/powerstone-icons';
import { useTranslation } from 'react-i18next';
import { languages, Apps } from 'utils';
import './ApplicationCatalog.css';
import { useHistory } from 'react-router';
import { getAllowedApps } from 'services/licensesApi';

const findRedirect = (items) => {
  let result = null;
  items.find((item) => {
    if (item.children) {
      const first_redirect = findRedirect(item.children);
      if (first_redirect) {
        result = first_redirect;
        return first_redirect;
      } else {
        return null;
      }
    }
    result = item;
    return item;
  });
  return result;
};

const ApplicationCatalog = ({ location, openModal, closeModal }) => {
  const { t } = useTranslation();
  const { showToast } = useToasts();
  const logout = useLogout(window.location.origin);
  const { user, resources } = useUserSessionState();
  const history = useHistory();
  const [allowedAppsResponse, getAllowedAppsFn] = useApiQuery(
    null,
    getAllowedApps,
  );
  const { data, error } = allowedAppsResponse;
  const { apps } = data;

  const handleLogout = () => {
    openModal({
      Icon: <FontAwesomeIcon icon={faPowerOff} />,
      modalTitle: t('LOGOUT'),
      component: (
        <ZModalDialog
          text={t('CONFIRM_LOGOUT')}
          onAccept={() => {
            logout();
            closeModal();
          }}
          onCancel={closeModal}
        />
      ),
    });
  };

  React.useEffect(() => {
    getAllowedAppsFn();
  }, []);

  if (error) {
    return <ErrorBanner errorMessage={t('ALLOWED_APPS_ERROR')} />;
  }

  return (
    <EmptyLayout>
      <Row xs={12} xl={12} className='mt-2 ml-2 mr-2 mb-2'>
        <EmptyLayoutSection className={'navbar-application-catalog'}>
          <div className='pull-right'>
            <NavLanguageSelector languages={languages} />
            <NavbarUser onLogout={handleLogout} />
          </div>
        </EmptyLayoutSection>
      </Row>
      <EmptyLayoutSection center width='100%'>
        <div className='application-catalog-container'>
          <div className='welcome-container'>
            <HeaderAuth />
            <h2>{`${t('WELCOME')}, ${user?.first_name || ''} ${
              user?.last_name || ''
            }`}</h2>
          </div>
          <div className='applications-container-catalog'>
            <div
              style={{
                gridTemplateColumns:
                  apps?.length > 7 ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)',
              }}
            >
              {apps &&
                apps.length > 0 &&
                apps.map((app) => {
                  return (
                    <div
                      className='app-item-catalog mr-2'
                      key={Apps[app]?.name}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (Apps[app]?.name === 'Management') {
                          const firstRedirect = findRedirect(resources);
                          if (!firstRedirect.to) {
                            showToast('error', t('ACCESS_NOT_ALLOWED'));
                          } else {
                            history.push(firstRedirect.to);
                          }
                        } else {
                          window.location.href = `${Apps[app]?.environment}`;
                        }
                      }}
                    >
                      {React.cloneElement(app ? Apps[app]?.logo : '')}
                      <div style={{ maxWidth: '400px' }}>
                        <h4 className='title mb-0'>{Apps[app]?.name}</h4>
                        {/* <p style={{ lineHeight: '1.25', fontStyle: 'italic' }}>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Sed fermentum.
                        </p> */}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </EmptyLayoutSection>
    </EmptyLayout>
  );
};

ApplicationCatalog.defaultProps = {
  location: { state: {} },
};

ApplicationCatalog.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({ username: PropTypes.string }),
  }),
};

export default withZModal(ApplicationCatalog);

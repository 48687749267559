import { Canvas, unmountComponentAtNode } from '@react-three/fiber';
import { FontAwesomeIcon, faTimes, faInfo } from '@zerintia/powerstone-icons';
import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { filesApi } from 'services/managementApi';
import styled from 'styled-components';

import { BACKGROUND_COLOR, CAMERA, DEFAULT_HEIGHT, FIGURES_3D } from './config';
import BeltInstance from './components/BeltInstance';
import CameraControls from './components/CameraControls';
import CubeInstance from './components/CubeInstance';
import Plane from './components/Plane';
import RegularPolygonInstance from './components/RegularPolygonInstance';
import TextMerged from './components/TextMerged';
import TransformationControls from './components/TransformationControls';

const Scene3DComponent = (
  { axes, controls = true, floor, models = [], origin, target, style, onTransformUpdate },
  ref
) => {
  const cameraControlsRef = useRef();
  const canvasRef = useRef();
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [openControls, setOpenControls] = useState(controls);
  const { t } = useTranslation();

  useEffect(() => {
    cameraControlsRef.current?.center();
    let canvas;
    setTimeout(() => {
      canvas = canvasRef.current;
    }, 1_000);
    const unmount = () => unmountComponentAtNode(canvas);
    window.addEventListener('beforeunload', unmount);
    return () => {
      unmount();
      window.removeEventListener('beforeunload', unmount);
    };
  }, []);

  useEffect(() => {
    const downloadImage = async () => {
      const { data } = await filesApi.filesFindOne({ id: floor.image });
      setImageUrl(data.content_url);
      setLoading(false);
    };

    if (typeof floor?.image === 'string') {
      downloadImage();
    } else if (floor?.image && typeof floor.image === 'object') {
      setImageUrl(floor.image.content_url);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [floor?.image]);

  useImperativeHandle(ref, () => ({
    center: () => cameraControlsRef.current?.center(),
  }));

  return (
    !loading && (
      <div className='position-relative'>
        <ControlsHelp>
          {openControls && (<>
            <p>
              <strong>{t('CONTROLS')}:</strong>
            </p>
            <p>
              <strong>{t('LEFT_BUTTON')}:</strong> {t('ROTATE')}{' '}
              {t('SCENE').toLowerCase()}
            </p>
            <p>
              <strong>{t('WHEEL')}:</strong> {t('ZOOM')}
            </p>
            <p>
              <strong>{t('RIGHT_BUTTON')}:</strong> {t('MOVE')}{' '}
              {t('SCENE').toLowerCase()}
            </p>
            <FontAwesomeIcon
              cursor='pointer'
              icon={faTimes}
              onClick={() => setOpenControls(false)}
            />
          </>)}
          {!openControls && (<>
            <FontAwesomeIcon
              cursor='pointer'
              icon={faInfo}
              onClick={() => setOpenControls(true)}
              style={{ top: 5, left: 10 }}
            />
          </>)}
        </ControlsHelp>
        <Canvas
          camera={CAMERA}
          ref={canvasRef}
          style={{
            backgroundColor: BACKGROUND_COLOR,
            height: DEFAULT_HEIGHT,
            ...style,
          }}
        >
          {models.some((model) => model.selected) && (
            <TransformationControls models={models.filter((model) => model.selected)} onTransformUpdate={onTransformUpdate} />
          )}
          <group>
            {models.some((model) => model.type === FIGURES_3D.belt) && (
              <BeltInstance
                belts={models.filter((model) => model.type === FIGURES_3D.belt)}
                ratio={1}
              />
            )}
            {models.some((model) => model.type === FIGURES_3D.cube) && (
              <CubeInstance
                cubes={models.filter((model) => model.type === FIGURES_3D.cube)}
                ratio={1}
              />
            )}
            {models.some(
              (model) => model.type === FIGURES_3D.cylinder
            ) && (
              <RegularPolygonInstance
                polygons={models.filter(
                  (model) => model.type === FIGURES_3D.cylinder
                )}
                ratio={1}
                smooth={true}
              />
            )}
            {models.some(
              (model) => model.type === FIGURES_3D.regular_polygon
            ) && (
              <RegularPolygonInstance
                polygons={models.filter(
                  (model) =>  model.type === FIGURES_3D.regular_polygon
                )}
                ratio={1}
                smooth={false}
              />
            )}
            {models.some((model) => model.text) && (
              <TextMerged
                ratio={1}
                texts={models.filter((model) => model.text)}
              />
            )}
            {imageUrl && <Plane {...floor} origin={origin} image={imageUrl} />}
          </group>
          <hemisphereLight
            groundColor='#000088'
            position={[-1, 1.5, 1]}
            skyColor='#ffffff'
          />
          <directionalLight
            color='#fff'
            intensity={1}
            position={[50, 50, 50]}
          />
          <CameraControls floor={floor} target={target} ref={cameraControlsRef} />
          {axes && <axesHelper args={[5]} />}
        </Canvas>
      </div>
    )
  );
};

const Scene3D = forwardRef(Scene3DComponent);

Scene3D.propTypes = {
  axes: PropTypes.bool,
  controls: PropTypes.bool,
  floor: PropTypes.shape({
    height: PropTypes.number, // px
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    ratio: PropTypes.number,
    width: PropTypes.number, // px
  }),
  models: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      onClick: PropTypes.func,
      position: PropTypes.shape({
        x: PropTypes.number.isRequired, // m
        y: PropTypes.number.isRequired, // m
        z: PropTypes.number.isRequired, // m
      }),
      rotation: PropTypes.shape({
        x: PropTypes.number.isRequired, // rad
        y: PropTypes.number.isRequired, // rad
        z: PropTypes.number.isRequired, // rad
      }),
      selected: PropTypes.bool,
      scale: PropTypes.shape({
        x: PropTypes.number.isRequired, // m
        y: PropTypes.number.isRequired, // m
        z: PropTypes.number.isRequired, // m
      }).isRequired,
      text: PropTypes.string,
      type: PropTypes.oneOf(Object.keys(FIGURES_3D)).isRequired,
      vertex_number: PropTypes.number,
    })
  ),
  origin: PropTypes.shape({
    x: PropTypes.number.isRequired, // px
    y: PropTypes.number.isRequired, // px
  }),
  style: PropTypes.object,
  onTransformUpdate: PropTypes.func,
};

export default Scene3D;

const ControlsHelp = styled.div`
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  color: '#333';
  margin: 0.5rem;
  padding: 0.8rem;
  position: absolute;
  bottom: 0;
  width: auto;
  z-index: 1;

  p {
    margin: 0;
  }

  p:first-of-type {
    font-size: 1.2rem;
  }

  svg {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
  }
`;

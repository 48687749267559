import React from 'react';
import { Route, Redirect, useRouteMatch } from 'react-router-dom';
import { useSession } from '../../hooks/useSession';
import { usePageConfig } from '@zerintia/powerstone-ui';

const PrivateRoute = ({ component: Component, routesMatch, ...rest }) => {
  const { activeSession } = useSession();
  const { updateCrumbs } = usePageConfig();
  const match = useRouteMatch({
    path: routesMatch.map(({ path }) => path),
    strict: true,
    sensitive: true,
    exact: true,
  });

  React.useEffect(() => {
    const crumbs = routesMatch
      .filter(({ path }) => match?.path.includes(path))
      .map(({ path, name }) => ({
        path: Object.keys(match.params).length
          ? Object.keys(match.params).reduce(
            (path, param) => path.replace(`:${param}`, match.params[param]),
            path
          )
          : path,

        name,
        state: rest?.location?.state || {}
      }));

    updateCrumbs(crumbs);
  }, [match, rest?.location?.state, routesMatch, updateCrumbs]);

  const render = React.useCallback(
    (props) => {
      return activeSession ? <Component {...props} exact /> : <Redirect to='/pages/login' exact />;
    },
    [activeSession]
  );

  return <Route {...rest} render={render} />;
};

export default PrivateRoute;

import { useFrame, useThree } from '@react-three/fiber';
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle, useRef, useEffect } from 'react';
import { OrbitControls } from '@react-three/drei';
import * as THREE from 'three';

const CameraControlsComponent = ({ floor, target }, ref) => {
  const {
    camera,
    gl: { domElement },
  } = useThree();
  const controls = useRef();

  const cameraTarget = target ? new THREE.Vector3(target[0], target[1], target[2]) : new THREE.Vector3(floor.width / 2 / floor.ratio, 0, floor.height / 2 / floor.ratio);

  const center = () => {
    const height = floor?.height || 5;
    const ratio = floor?.ratio || 1;
    const width = floor?.width || 5;

    const size = Math.max(height, width);
    const angle = (Math.PI * camera.fov) / 180 / 2;
    const tanAngle = camera.aspect * Math.tan(angle);
    const d = size / tanAngle / ratio;

    const v = new THREE.Vector3(1, 4, 4);
    v.setLength(Math.min(d * 2, camera.far));
    v.add(cameraTarget);
    camera.position.set(v.x, v.y, v.z);
    
    camera.updateProjectionMatrix();
    controls.current.target = cameraTarget;
    controls.current.update();
  };

  useFrame(() => controls.current.update());

  useImperativeHandle(ref, () => ({ center }));

  useEffect(() => {
    center();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <OrbitControls
      args={[camera, domElement]}
      ref={controls}
      target={cameraTarget}
      minPolarAngle={0.01}
      maxPolarAngle={Math.PI / 2}
      rotateSpeed ={0.5}
      makeDefault
    />
  );
};

const CameraControls = forwardRef(CameraControlsComponent);

CameraControls.propTypes = {
  floor: PropTypes.shape({
    height: PropTypes.number, // px
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    ratio: PropTypes.number,
    width: PropTypes.number, // px
  }),
};

export default CameraControls;

import { ZInput } from '@zerintia/powerstone-form';
import { InputGroup, InputGroupAddon } from '@zerintia/powerstone-ui';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import { useFormContext } from 'react-hook-form';

const ZColorPicker = ({
  defaultValue,
  disabled,
  name,
  onChange,
  placeholder,
  required,
}) => {
  const { setValue, watch } = useFormContext();
  const [open, setOpen] = useState(false);

  const RGBAtoHex = ({ r, g, b, a }) => {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);
    a = Math.round(a * 255).toString(16);

    if (r.length === 1) r = '0' + r;
    if (g.length === 1) g = '0' + g;
    if (b.length === 1) b = '0' + b;
    if (a.length === 1) a = '0' + a;

    return '#' + r + g + b + a;
  };

  const setColor = (color) => {
    onChange?.(name, RGBAtoHex(color.rgb));
    setValue(name, RGBAtoHex(color.rgb));
  };

  useEffect(() => {
    window.addEventListener('click', () => setOpen(false));
    return () => window.removeEventListener('click', () => setOpen(false));
  }, []);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, name, setValue]);

  const value = watch(name);

  return (
    <>
      <InputGroup>
        {value && (
          <InputGroupAddon
            addonType='prepend'
            className='rounded-left'
            style={{ backgroundColor: value, width: 50 }}
          />
        )}
        <ZInput
          disabled={disabled}
          name={name}
          onClick={(event) => {
            event.stopPropagation();
            setOpen(!open);
          }}
          placeholder={placeholder}
          required={required}
          type='text'
          value={value || ''}
        />
      </InputGroup>
      {open && (
        <div
          onClick={(event) => event.stopPropagation()}
          style={{ position: 'absolute', zIndex: 100 }}
        >
          <SketchPicker color={value} onChange={setColor} />
        </div>
      )}
    </>
  );
};

ZColorPicker.propTypes = {
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};

export default ZColorPicker;

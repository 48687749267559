import * as React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  faCircleNotch,
  faPowerOff,
  faUser,
  FontAwesomeIcon,
} from '@zerintia/powerstone-icons';
import {
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  PageLoader,
  useToasts,
} from '@zerintia/powerstone-ui';
import { PageContainer } from '../../components';
import { authApi, usersSessionApi, sitesApi, userStatesApi } from 'services/managementApi';

import { useUserSessionUpdater, useLogout } from '@zerintia/powerstone-auth';
import style from './SessionProfiles.module.css';

const Profiles = ({ location }) => {
  const dispatch = useUserSessionUpdater();
  const history = useHistory();
  const { t } = useTranslation();
  const { showToast } = useToasts();
  const { user, redirect_uri } = location.state;
  const [loading, setLoading] = React.useState(false);
  const logout = useLogout(window.location.origin + process.env.PUBLIC_URL);

  const handleSelectProfile = React.useCallback(
    async (profile) => {
      try {
        const userSessionResponse =
          await usersSessionApi.userSessionPrepareUserSession({
            prepareUserSessionInputDto: {
              profile_id: profile.id,
              email: user.email,
            },
          });

        const privileges = userSessionResponse.data.privileges;
        const usersPrivileges = privileges.users;
        if (!usersPrivileges.manage) {
          showToast('error', t('ACCESS_DENIED'));
          throw new Error();
        }

        const availableSites = userSessionResponse.data.attributes?.sites;
        const activeSites =
          userSessionResponse.data.attributes?.active_sites || [];
        let sites;

        if (availableSites?.length === 0) {
          const { data } = await sitesApi.sitesFindAll();
          sites = data.items;
        } else {
          const { data } = await sitesApi.sitesFindAll({
            filters: ['_id'],
            values: [`|${availableSites.join(',')}`],
          });
          sites = data.items;
        }

        const userMenuResponse = await authApi.authenticationGetUserMenu();

        const userState = await userStatesApi.userStateFindUserState({
          id: user._id,
        });

        dispatch({
          type: 'update',
          payload: {
            user,
            profileId: userSessionResponse.data.profile,
            sites: sites.map((site) => ({
              ...site,
              selected:
                activeSites.length === 0 || activeSites.includes(site._id),
            })),
            privileges: userSessionResponse.data.privileges,
            resources: userMenuResponse.data,
            changePassword: userState.data.change_password,
          },
        });

        //REDIRECT TO LANDING-PAGE APLICATION-CATALOGUE
        if (redirect_uri) {
          window.location = redirect_uri;
        } else {
          history.push('/pages/application-catalog');
        }
      } catch (err) {
        showToast('error', t('ERROR_ON_INIT_SESSION'));
        setLoading(false);
        sessionStorage.clear();
        dispatch({
          type: 'deactivateSession',
          payload: {
            activeSession: false,
            success: false,
            loading: false,
          },
        });
        history.push('/pages/login');
      }
    },
    [dispatch, user, history, showToast, t, redirect_uri]
  );

  React.useLayoutEffect(() => {
    if (user.profiles.length === 1) {
      handleSelectProfile(user.profiles[0]);
    }
  }, [user, handleSelectProfile]);

  return user.profiles.length > 1 ? (
    <PageContainer>
      <Card className={style.widthContainer}>
        <CardBody className='p-0'>
          <Link
            to='/pages/login'
            title={t('LOGOUT')}
            className='btn d-flex justify-content-end pt-3'
            onClick={logout}
          >
            <FontAwesomeIcon icon={faPowerOff} />
          </Link>
          <div className='d-flex justify-content-center my-3 text-primary'>
            <FontAwesomeIcon icon={faUser} className='fa-5x' />
          </div>
          <div className='mb-4 text-center'>
            <h5>
              {user.firstName} {user.lastName}
            </h5>
          </div>
          <div className='mb-4 text-center'>
            {loading ? (
              <FontAwesomeIcon icon={faCircleNotch} className='fa-spin' />
            ) : (
              t('SELECT_PROFILE')
            )}
          </div>
          <Nav pills vertical>
            {user.profiles &&
              user.profiles.map((profile, index) => (
                <NavItem key={`item-${index}`}>
                  <NavLink
                    className={style.navLink}
                    onClick={() => handleSelectProfile(profile)}
                    href='#'
                  >
                    {profile.name}
                  </NavLink>
                </NavItem>
              ))}
          </Nav>
        </CardBody>
      </Card>
    </PageContainer>
  ) : (
    <PageContainer>
      <PageLoader loadingText={t('LOADING_PROFILES')} />
    </PageContainer>
  );
};

Profiles.defaultProps = {
  location: { state: {} },
};

Profiles.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({ user: PropTypes.object }),
  }),
};
export default Profiles;

import * as React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon, faClipboard } from '@zerintia/powerstone-icons';
import {
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  UncontrolledTooltip,
} from '@zerintia/powerstone-ui';

const CopyToClipboard = ({ id, value }) => {
  const { t } = useTranslation();
  const inputRef = React.useRef(null);

  const copyToClipboard = (e) => {
    inputRef.current.select();
    document.execCommand('copy');
    e.target.focus();
  };

  return (
    <InputGroup>
      <Input innerRef={inputRef} defaultValue={value} id={id} readOnly />
      {document.queryCommandSupported('copy') ? (
        <InputGroupAddon addonType='append'>
          <Button
            aria-label='Copy To Clipboard'
            onClick={copyToClipboard}
            color='secondary'
            outline
            id={`${id}-clipboard`}
          >
            <FontAwesomeIcon icon={faClipboard} />
          </Button>
          <UncontrolledTooltip placement='top' target={`${id}-clipboard`}>
            {t('COPY_TO_CLIPBOARD')}
          </UncontrolledTooltip>
        </InputGroupAddon>
      ) : null}
    </InputGroup>
  );
};

CopyToClipboard.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default CopyToClipboard;

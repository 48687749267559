import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon, faTimesCircle } from '@zerintia/powerstone-icons';
import { Card, CardTitle, CardText } from '@zerintia/powerstone-ui';
import { useTranslation } from 'react-i18next';

const ErrorBanner = ({ errorMessage }) => {
  const { t } = useTranslation();

  return (
    <section className='w-100 d-flex justify-content-center'>
      <section className='text-center mt-3 w-75'>
        <Card body>
          <CardTitle>
            <h4 className='text-danger'>
              <FontAwesomeIcon icon={faTimesCircle} />
            </h4>
          </CardTitle>
          <CardText>{errorMessage || t('SOMETHING_WENT_WRONG')}</CardText>
        </Card>
      </section>
    </section>
  );
};

ErrorBanner.propTypes = {
  errorMessage: PropTypes.string,
};

export default ErrorBanner;

export const BACKGROUND_COLOR = '#f0f0f0';
export const CAMERA = {
  far: 3000,
  fov: 25,
  near: 1,
  position: [5, 15, 15],
  zoom: 1,
};
export const DEFAULT_COLOR = '#256ede';
export const DEFAULT_HEIGHT = 600;
export const EDITING_COLOR = '#ff8000';
export const FIGURES_3D = {
  cylinder: 'cylinder',
  belt: 'belt',
  cube: 'cube',
  regular_polygon: 'regular_polygon',
};
export const MAX_VERTICES = 12;
export const MIN_VERTICES = 3;
export const TEXT_COLOR = '#d31f1f';

import axios from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container } from 'reactstrap';

const PreviewFile = ({ file, onCancel, allowDownload }) => {
  const { t } = useTranslation();

  const onDownload = () => {
    axios.get(file.content_url, { responseType: 'blob' }).then(function (blob) {
      // It is necessary to create a new blob object with mime-type explicitly set
      // otherwise only Chrome works like it should
      let newBlob = new Blob([blob.data], { type: file.mimeType });
      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }
      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      let data = window.URL.createObjectURL(newBlob);
      let link = document.createElement('a');
      link.href = data;
      link.download = file.name;
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    });
  };

  return (
    <Container>
      <div style={{textAlign:'center'}}>
        {file.mimeType.includes('image') && (
          <img
            width='auto'
            style={{ maxHeight: '70vh', maxWidth: '100%' }}
            src={file.content_url}
            alt={'images'}
          ></img>
        )}
        {file.mimeType.includes('video') && (
          <video
            controls='true'
            src={file.content_url}
            width='auto'
            style={{ maxHeight: '70vh', maxWidth: '100%' }}
          ></video>
        )}
      </div>
      <section className='d-flex justify-content-end mt-2'>
        {allowDownload && (
          <Button
            type='button'
            color='primary'
            onClick={onDownload}
            className='mr-1'
          >
            {t('DOWNLOAD')}
          </Button>
        )}
        <Button type='button' onClick={onCancel}>
          {t('CANCEL')}
        </Button>
      </section>
    </Container>
  );
};

export default PreviewFile;

import React, { useRef } from 'react';
import { TransformControls } from '@react-three/drei';
import * as THREE from 'three';

import { EDITING_COLOR, FIGURES_3D } from '../config';

const TransformationControls = ({ models, onTransformUpdate }) => {
  const transformControl = useRef();
  const meshRef = useRef();

  const bb = new THREE.Box3();
  const group = new THREE.Group();
  models.forEach((m) => {
    let g;
    switch(m.type) {
      case FIGURES_3D.cube:
      case FIGURES_3D.belt:
        g = new THREE.BoxGeometry(1, 1, 1);
        break;
      case FIGURES_3D.cylinder:
      case FIGURES_3D.regular_polygon:
        g = new THREE.CylinderGeometry(0.5, 0.5, 1, m.vertex_number);
        break;
      default:
        return;
    }
    const mesh = new THREE.Mesh( g );
    mesh.position.set(m.position.x, m.position.y, m.position.z);
    mesh.rotation.set(m.rotation.x, m.rotation.y, m.rotation.z);
    mesh.scale.set(m.scale.x, m.scale.y, m.scale.z);
    group.add(mesh);
  });
  bb.setFromObject(group);

  return (
    <>
      <TransformControls ref={transformControl} position={[(bb.max.x + bb.min.x) / 2, (bb.max.z + bb.min.z) / 2, (bb.max.y + bb.min.y) / 2]} onMouseUp={() => {
        const newPos = transformControl.current.positionStart.clone().add(transformControl.current.offset);
        onTransformUpdate({ target: { value: Math.round(newPos.x * 100) / 100 }}, 'x');
        onTransformUpdate({ target: { value: Math.round(newPos.z * 100) / 100 }}, 'y');
        onTransformUpdate({ target: { value: Math.round(newPos.y * 100) / 100 }}, 'z');
      }}>
        <mesh ref={meshRef} position={[0, (bb.max.z - bb.min.z) / 2, 0]}>
          <boxBufferGeometry attach='geometry' args={[bb.max.x - bb.min.x, bb.max.z - bb.min.z, bb.max.y - bb.min.y]} />
          <meshBasicMaterial attach='material' color={EDITING_COLOR} wireframe />
        </mesh>
      </TransformControls>
    </>
  );
};

export default TransformationControls;
